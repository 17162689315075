define("js-common/models/subject", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/typeof", "ember-api-actions", "ember-data", "moment"], function (_exports, _slicedToArray2, _typeof2, _emberApiActions, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;

  var _default = Model.extend({
    memberId: attr('number'),
    draftMemberId: attr('number'),
    firstName: attr('string'),
    lastName: attr('string'),
    middleName: attr('string'),
    gender: attr('string'),
    genderNotes: attr('string'),
    dob: attr('string'),
    dod: attr('string'),
    relationship: attr('string'),
    relationshipNotes: attr('string'),
    // salt not used in UI
    ssan: attr('string'),
    ssanNotes: attr('string'),
    alias: attr('string'),
    age: attr('string'),
    ageNotes: attr('string'),
    race: attr('string'),
    raceNotes: attr('string'),
    nationalOrigin: attr('string'),
    nationalOriginNotes: attr('string'),
    employment: attr('string'),
    employmentNotes: attr('string'),
    general: attr('string'),
    custom1: attr('string'),
    custom2: attr('string'),
    custom3: attr('string'),
    custom4: attr('string'),
    custom5: attr('string'),
    custom6: attr('string'),
    custom7: attr('string'),
    custom8: attr('string'),
    custom9: attr('string'),
    custom10: attr('string'),
    custom11: attr('string'),
    custom12: attr('string'),
    custom13: attr('string'),
    custom14: attr('string'),
    custom15: attr('string'),
    civil: attr('string'),
    civilNotes: attr('string'),
    civilFormPublicNotes: attr('string'),
    civilFormInternalNotes: attr('string'),
    criminal: attr('string'),
    criminalHistory: attr('string'),
    criminalFormPublicNotes: attr('string'),
    criminalFormInternalNotes: attr('string'),
    counties: attr('string'),
    countyNotes: attr('string'),
    affiliation: attr('string'),
    affiliationHistory: attr('string'),
    affiliationNotes: attr('string'),
    contributions: attr('string'),
    misc: attr('string'),
    miscNotes: attr('string'),
    socialMedia: attr('string'),
    socialMediaNotes: attr('string'),
    birth: attr('string'),
    education: attr('string'),
    educationNotes: attr('string'),
    medical: attr('string'),
    medicalNotes: attr('string'),
    marital: attr('string'),
    maritalNotes: attr('string'),
    bankruptcy: attr('string'),
    bankruptcyNotes: attr('string'),
    residential: attr('string'),
    residentialNotes: attr('string'),
    death: attr('string'),
    deathInformation: attr('string'),
    scope: attr('string'),
    traffic: attr('string'),
    trafficNotes: attr('string'),
    family: attr('string'),
    familyNotes: attr('string'),
    results: attr('string'),
    supplementalResearch: attr('string'),
    flagStatus: attr('string'),
    flagNote: attr('string'),
    identity: attr('string'),
    highlights: attr('string'),
    subjectType: attr('string', {
      defaultValue: 'other_subjects'
    }),
    smNotes: attr('string'),
    monitor: attr('number'),
    monitorFrequency: attr('string'),
    notes: attr('string'),
    identityStatus: attr('string'),
    socnetNotifiedOn: attr('estdate'),
    socnetNotifiedBy: attr('number'),
    vehicle: attr('string'),
    expertWitnessHistory: attr('string'),
    expertWitnessHistoryNotes: attr('string'),
    published: attr('boolean'),
    pending: attr('boolean'),
    publishedOn: attr('estdate'),
    unpublishedOn: attr('estdate'),
    caseClassification: attr('string'),
    researchDisclaimer: _emberData.default.attr('string'),
    hasBeenViewedOn: attr('utcdate'),
    location: attr('string'),
    locationNotes: attr('string'),
    locationOrder: attr('number'),
    foreperson: attr('number'),
    seatNumber: attr('number'),
    courtPanel: attr('string'),
    draftCourtPanel: attr('string'),
    courtJuror: attr('string'),
    injured: attr('string'),
    broughtSuit: attr('string'),
    deceased: attr('string'),
    forensicCapture: attr('string'),
    depDate: attr('string'),
    civilJudgmentsOrLiens: attr('string'),
    foreclosure: attr('string'),
    federalCivil: attr('string'),
    workersCompensation: attr('string'),
    captureStatus: attr('string'),
    publishedMessage: attr('string'),
    publishedOption: attr('string'),
    // client fields
    clientNotes: attr('string'),
    clientRanking: attr('number'),
    // control in the backend
    saveOrderPanel: attr('number', {
      defaultValue: 0
    }),
    // optional
    keyAttributes: attr(),
    avatarUrl: attr(),
    clientTags: attr(),
    tags: attr(),
    // resource fields
    childTable: attr('string', {
      defaultValue: 'subjects'
    }),
    syncId: attr('number'),
    createdOn: attr('estdate'),
    updatedOn: attr('estdate'),
    fullProfileUpdatedOn: attr('estdate'),
    viewable: attr('number'),
    createdBy: belongsTo('user', {
      async: true
    }),
    updatedBy: belongsTo('user', {
      async: true
    }),
    copiedFromId: attr('number'),
    fullProfileUpdatedBy: belongsTo('user', {
      async: true
    }),
    // calculated
    fullName: Ember.computed('firstName', 'lastName', function () {
      if (Ember.isEmpty(this.lastName) && Ember.isEmpty(this.firstName)) {
        return null;
      }

      if (Ember.isPresent(this.lastName) && Ember.isEmpty(this.firstName)) {
        return this.lastName;
      }

      if (Ember.isPresent(this.lastName) && Ember.isPresent(this.firstName)) {
        return "".concat(this.lastName, ", ").concat(this.firstName);
      }

      return "".concat(this.lastName, ", ").concat(this.firstName);
    }),
    firstLastName: Ember.computed('firstName', 'lastName', function () {
      if (Ember.isEmpty(this.lastName) && Ember.isEmpty(this.firstName)) {
        return null;
      }

      if (Ember.isPresent(this.lastName) && Ember.isEmpty(this.firstName)) {
        return this.lastName;
      }

      if (Ember.isPresent(this.lastName) && Ember.isPresent(this.firstName)) {
        return "".concat(this.firstName, " ").concat(this.lastName);
      }

      return "".concat(this.firstName, " ").concat(this.lastName);
    }),
    displayLastName: Ember.computed('subjectType', function () {
      switch (this.subjectType) {
        case 'other_subjects':
          return 'Last Name / Entity Name';

        default:
          return 'Last Name';
      }
    }),
    fullNameWithMiddle: Ember.computed('fullName', 'middleName', function () {
      var fullName = this.fullName;
      var middleName = this.middleName;

      if (middleName) {
        fullName = "".concat(fullName, " ").concat(this.middleName);
      }

      return fullName;
    }),
    hasNumbers: Ember.computed('numbers', function () {
      return !Ember.isEmpty(this.numbers);
    }),
    numbers: Ember.computed('memberId', 'courtPanel', 'courtJuror', function () {
      var memberId = this.memberId,
          courtPanel = this.courtPanel,
          courtJuror = this.courtJuror;
      return [memberId, courtPanel, courtJuror].filter(function (item) {
        return !Ember.isEmpty(item);
      });
    }),
    nameAndNumbers: Ember.computed('fullName', 'numbers', function () {
      var fullName = this.fullName,
          numbers = this.numbers;
      var numbersValue = numbers.join('/');
      return [numbersValue, fullName].join(' - ');
    }),
    presentKeyAttributes: Ember.computed('keyAttributes', function () {
      if (Ember.isEmpty(this.keyAttributes)) return [];
      return this.keyAttributes.reject(function (x) {
        return x === null;
      });
    }),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    sortBy: ['date'],
    sortedTimelineEvents: Ember.computed.sort('timelineEvents', 'sortBy'),
    hasEvents: function hasEvents() {
      return this.timelineEvents.length > 0;
    },

    /* custom actions
       /subjects/123/publish */
    publish: (0, _emberApiActions.memberAction)({
      path: 'publish',
      type: 'put'
    }),
    publishLimited: (0, _emberApiActions.memberAction)({
      path: 'publish-limited',
      type: 'put'
    }),
    unpublish: (0, _emberApiActions.memberAction)({
      path: 'unpublish',
      type: 'put'
    }),
    reset: (0, _emberApiActions.memberAction)({
      path: 'reset',
      type: 'put'
    }),
    makeViewable: (0, _emberApiActions.memberAction)({
      path: 'make_viewable',
      type: 'put'
    }),
    renumberSubjects: (0, _emberApiActions.memberAction)({
      path: 'renumber_subjects',
      type: 'post'
    }),
    patch: (0, _emberApiActions.memberAction)({
      path: 'patch',
      type: 'put'
    }),
    getNoteCommunications: (0, _emberApiActions.memberAction)({
      path: 'note_communications',
      type: 'get'
    }),
    saveFlag: (0, _emberApiActions.memberAction)({
      path: 'flag',
      type: 'put'
    }),
    saveAnalytic: (0, _emberApiActions.memberAction)({
      path: 'analytic',
      type: 'put'
    }),
    saveWorkflow: (0, _emberApiActions.memberAction)({
      path: 'workflow',
      type: 'put'
    }),
    getMatchedJurors: (0, _emberApiActions.memberAction)({
      path: 'get_matched_jurors',
      type: 'get'
    }),
    // relationships
    matter: belongsTo('matter', {
      async: false
    }),
    copiedFrom: belongsTo('subject', {
      async: true
    }),
    simpleWorkflows: hasMany('simple-workflow', {
      async: false
    }),
    subjectRevisions: hasMany('subject-revision', {
      async: false
    }),
    documents: hasMany('document', {
      async: false,
      inverse: 'subject'
    }),
    visibleDocuments: Ember.computed('documents', function () {
      return this.documents.rejectBy('docType', 'MARKDOWN_UPLOAD');
    }),
    // using the serializer to transform the note_ids to subject_note_ids
    subjectNotes: hasMany('note', {
      async: false
    }),
    hyperlinks: hasMany('hyperlink', {
      async: false
    }),
    publicHyperlinks: Ember.computed('hyperlinks.@each.viewable', function () {
      return this.hyperlinks.filterBy('viewable', 1).filterBy('doNotUse', 0);
    }),
    dbMemos: hasMany('db-memo', {
      async: false
    }),
    timelineEvents: hasMany('timeline-event', {
      async: false
    }),
    addresses: hasMany('subject-addr', {
      async: false
    }),
    monitoringActivity: Ember.computed('subjectNotes.[]', function () {
      return this.subjectNotes.filterBy('viewable').filterBy('noteType', '1');
    }),
    monitoringNotes: Ember.computed('subjectNotes.[]', function () {
      return this.subjectNotes.rejectBy('viewable').filterBy('noteType', '1');
    }),
    normalNotes: Ember.computed.filterBy('subjectNotes', 'noteType', '0'),
    numberOfDisplayedEvents: Ember.computed('timelineEvent.[]', 'timelineEvents', function () {
      return this.timelineEvents.reduce(function (acc, te) {
        return acc + (Ember.isPresent(te.endDate) ? 2 : 1);
      }, 0);
    }),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    monitoringSort: ['createdOn:desc'],
    sortedMonitoringActivity: Ember.computed.sort('monitoringActivity', 'monitoringSort'),
    sortedMonitoringNotes: Ember.computed.sort('monitoringNotes', 'monitoringSort'),
    latestMonitoringActivity: Ember.computed('sortedMonitoringActivity', function () {
      return this.sortedMonitoringActivity.slice(0, 2);
    }),
    latestMonitoringNotes: Ember.computed('sortedMonitoringNotes', function () {
      return this.sortedMonitoringNotes.slice(0, 2);
    }),
    isChecked: attr('boolean'),
    showIsResearchedBadge: Ember.computed('caseClassification', function () {
      var classification = this.caseClassification;

      if (Ember.isEmpty(classification)) {
        classification = '';
      }

      return classification.indexOf('Case Subject') >= 0;
    }),
    showIsFlagged: Ember.computed.notEmpty('flagNote'),
    publishedBy: belongsTo('user', {
      async: true
    }),
    formattedPublishedOn: Ember.computed('publishedOn', function () {
      var publishedOn = this.publishedOn;

      if (!(0, _moment.default)(publishedOn).isValid()) {
        return '';
      }

      return (0, _moment.default)(publishedOn).format('MM/DD/YYYY @ h:mm a');
    }),
    unpublishedBy: belongsTo('user', {
      async: true
    }),
    switchSubject: (0, _emberApiActions.memberAction)({
      path: 'switch_subject',
      type: 'get',
      urlType: 'findRecord'
    }),
    recordView: (0, _emberApiActions.memberAction)({
      path: 'record_view',
      type: 'put'
    }),
    // analytics fields
    states: _emberData.default.attr('string'),
    statesOther: _emberData.default.attr('string'),
    property: _emberData.default.attr('string'),
    primaryPropertyValue: _emberData.default.attr('string'),
    civilPlaintiff: _emberData.default.attr('string'),
    civilDefendant: _emberData.default.attr('string'),
    civilBankruptcies: _emberData.default.attr('string'),
    civilUnknown: _emberData.default.attr('string'),
    civilWorkcomp: _emberData.default.attr('string'),
    civilAnalysis: _emberData.default.attr('string'),
    civilOutsideOfScope: _emberData.default.attr('number'),
    civilPartytype: _emberData.default.attr('string'),
    criminalTotal: _emberData.default.attr('string'),
    criminalAnalysis: _emberData.default.attr('string'),
    criminalCharge: _emberData.default.attr('string'),
    criminalRights: _emberData.default.attr('string'),
    criminalCases: _emberData.default.attr('string'),
    trafficAnalysis: _emberData.default.attr('string'),
    employmentAnalysis: _emberData.default.attr('string'),
    employmentAnalysisCategory: _emberData.default.attr('string'),
    employmentAnalysisLicense: _emberData.default.attr('string'),
    employmentAnalysisConnections: _emberData.default.attr('string'),
    employmentStatus: _emberData.default.attr('string'),
    employmentFactors: _emberData.default.attr('string'),
    employmentFactorsVeteran: _emberData.default.attr('string'),
    employmentPosition: _emberData.default.attr('string'),
    employmentPositionCurrent: _emberData.default.attr('string'),
    employmentLengthCurrent: _emberData.default.attr('string'),
    socnetActivity: _emberData.default.attr('string'),
    socnetThemes: _emberData.default.attr('string'),
    socnetThemesHidden: _emberData.default.attr('string'),
    socnetThemesOther: _emberData.default.attr('string'),
    socnetTones: _emberData.default.attr('string'),
    socnetTonesOther: _emberData.default.attr('string'),
    socnetConnections: _emberData.default.attr('string'),
    socnetOther: _emberData.default.attr('string'),
    socnetNote: _emberData.default.attr('string'),
    possibleConnectionsCategory: _emberData.default.attr('string'),
    educationAnalysis: _emberData.default.attr('string'),
    higherEducation: _emberData.default.attr('string'),
    higherEducationMasters: _emberData.default.attr('string'),
    higherEducationPostGrad: _emberData.default.attr('string'),
    religiousInterests: _emberData.default.attr('string'),
    religiousAffiliation: _emberData.default.attr('string'),
    religiousLevelOfInterest: _emberData.default.attr('string'),
    maritalStatus: _emberData.default.attr('string'),
    maritalAttributes: _emberData.default.attr('string'),
    maritalStability: _emberData.default.attr('string'),
    children: _emberData.default.attr('string'),
    hasChildren: _emberData.default.attr('string'),
    employmentType: _emberData.default.attr('string'),
    employmentTypeCurrent: _emberData.default.attr('string'),
    employmentRole: _emberData.default.attr('string'),
    employmentOther: _emberData.default.attr('string'),
    employmentCurrentLast: _emberData.default.attr('string'),
    jobLevel: _emberData.default.attr('string'),
    employmentStability: _emberData.default.attr('string'),
    height: _emberData.default.attr('string'),
    occupations: _emberData.default.attr('string'),
    productUse: _emberData.default.attr('string'),
    productUseSecondaries: _emberData.default.attr('string'),
    addictionWithdrawalExperience: _emberData.default.attr('string'),
    jurorMedicalIssues: _emberData.default.attr('string'),
    similarProductNotes: _emberData.default.attr('string'),
    residentialStability: _emberData.default.attr('string'),
    politicalViews: _emberData.default.attr('string'),
    mediaOutlet: _emberData.default.attr('string'),
    mediaOutletNotes: _emberData.default.attr('string'),
    priorJurorService: _emberData.default.attr('string'),
    verdictReached: _emberData.default.attr('string'),
    forepersonHistory: _emberData.default.attr('string'),
    marriageCount: _emberData.default.attr('string'),
    yearsMarried: _emberData.default.attr('string'),
    yearsEmployed: _emberData.default.attr('string'),
    jobSatisfaction: _emberData.default.attr('string'),
    yearsInState: _emberData.default.attr('string'),
    longTermResident: _emberData.default.attr('string'),
    yearsInCounty: _emberData.default.attr('string'),
    questionnaire: _emberData.default.attr('string'),
    governmentSector: _emberData.default.attr('string'),
    governmentSectorArea: _emberData.default.attr('string'),
    governmentSectorBranches: _emberData.default.attr('string'),
    governmentSectorCurrent: _emberData.default.attr('string'),
    currentCity: _emberData.default.attr('string'),
    militaryVeteran: _emberData.default.attr('string'),
    militaryVeteranBranches: _emberData.default.attr('string'),
    militaryVeteranRank: _emberData.default.attr('string'),
    militaryVeteranYearsOfService: _emberData.default.attr('string'),
    militaryVeteranCurrent: _emberData.default.attr('string'),
    isGovSector: Ember.computed('governmentSectorCurrent', function () {
      var governmentSector = this.governmentSectorCurrent;
      return governmentSector && governmentSector === '1';
    }),
    isMilitaryVet: Ember.computed('militaryVeteranCurrent', function () {
      var militaryVeteranCurrent = this.militaryVeteranCurrent;
      return militaryVeteranCurrent && militaryVeteranCurrent === '1';
    }),
    // analytics computed properties
    subjectAnalyticsStates: Ember.computed('states', {
      get: function get() {
        var states = this.states;

        if (states) {
          return states.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedStates = value.filterBy('checked', true);

        if (!selectedStates.findBy('display', 'Other')) {
          this.set('statesOther', null);
        }

        this.set('states', selectedStates.map(function (s) {
          return s.get('display');
        }).join(', '));
        return selectedStates.map(function (item) {
          return item.get('display');
        });
      }
    }),
    subjectAnalyticsStatesRead: Ember.computed('subjectAnalyticsStates', function () {
      return "\n            ".concat(this.subjectAnalyticsStates.map(function (item) {
        return "\n              ".concat(item, "<br/>\n            ").trim();
      }).join(''), "\n          ");
    }),
    subjectAnalyticsCivil: Ember.computed('civilAnalysis', {
      get: function get() {
        if (!this.civilAnalysis) {
          return [];
        }

        if (this.civilAnalysis === 'No Civil Records') {
          return this.civilAnalysis;
        }

        var civilAnalysis = JSON.parse(this.civilAnalysis);
        var civilData = [];
        civilAnalysis.forEach(function (item) {
          civilData.push(Ember.Object.create({
            order: item.order,
            year: item.year,
            case_type: item.case_type,
            case_type_analytics_key: item.case_type_analytics_key,
            case_type_analytics_label: item.case_type_analytics_label,
            party_type: item.party_type,
            party_type_analytics: item.party_type_analytics,
            case_notes: item.case_notes,
            qty: item.qty ? item.qty : 1,
            is_markdown: item.is_markdown
          }));
        });
        return civilData;
      },
      set: function set(key, value) {
        if (!value || (0, _typeof2.default)(value) === 'object' && !value.length) {
          this.set('civilAnalysis', null);
          return [];
        }

        if (value === 'No Civil Records') {
          this.set('civilAnalysis', value);
          return value;
        }

        this.set('civilAnalysis', JSON.stringify(value));
        return value;
      }
    }),
    subjectAnalyticsCriminalCases: Ember.computed('criminalCases', {
      get: function get() {
        if (!this.criminalCases) {
          return [];
        }

        if (this.criminalCases === 'No Criminal Records') {
          return this.criminalCases;
        }

        var criminalCases = JSON.parse(this.criminalCases);
        var criminalData = [];
        criminalCases.forEach(function (item) {
          criminalData.push(Ember.Object.create({
            order: item.order,
            year: item.year,
            case_type: item.case_type,
            case_type_analytics_key: item.case_type_analytics_key,
            case_type_analytics_label: item.case_type_analytics_label,
            case_notes: item.case_notes,
            qty: item.qty ? item.qty : 1,
            is_markdown: item.is_markdown
          }));
        });
        return criminalData;
      },
      set: function set(key, value) {
        if (!value || (0, _typeof2.default)(value) === 'object' && !value.length) {
          this.set('criminalCases', null);
          return [];
        }

        if (value === 'No Criminal Records') {
          this.set('criminalCases', value);
          return value;
        }

        this.set('criminalCases', JSON.stringify(value));
        return value;
      }
    }),
    subjectAnalyticsCivilPartytype: Ember.computed('civilPartytype', {
      get: function get() {
        var civilPartytype = this.civilPartytype ? JSON.parse(this.civilPartytype) : false;

        if (civilPartytype) {
          return Object.keys(civilPartytype).map(function (k) {
            return Ember.Object.create({
              display: k,
              plaintiff: civilPartytype[k][0],
              defendant: civilPartytype[k][1],
              other: civilPartytype[k][2]
            });
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedCivil = value.filterBy('checked', true);

        if (selectedCivil) {
          var noneSelected = selectedCivil.findBy('shortName', 'no_civil_records');

          if (noneSelected) {
            this.set('civilPartytype', null);
            return null;
          }

          var civilPartytypeObj = {};
          selectedCivil.forEach(function (c) {
            civilPartytypeObj[c.get('shortName')] = [c.get('plaintiffCount').toString(), c.get('defendantCount').toString(), c.get('otherCount').toString()];
          });
          this.set('civilPartytype', JSON.stringify(civilPartytypeObj));
          return Object.keys(civilPartytypeObj).map(function (k) {
            return Ember.Object.create({
              display: k,
              plaintiff: civilPartytypeObj[k][0],
              defendant: civilPartytypeObj[k][1],
              other: civilPartytypeObj[k][2]
            });
          });
        }

        return false;
      }
    }),
    subjectAnalyticsCriminal: Ember.computed('criminalAnalysis', {
      get: function get() {
        var criminalAnalysis = this.criminalAnalysis;

        if (criminalAnalysis) {
          return criminalAnalysis.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedCriminal = value.criminalAnalysis.filterBy('checked', true);
        var noneOption = value.criminalAnalysis.findBy('shortName', 'no_criminal');

        if (selectedCriminal) {
          this.set('criminalAnalysis', selectedCriminal.map(function (c) {
            return c.get('display');
          }).join(', '));

          if (noneOption.get('checked')) {
            this.set('criminalTotal', '');
          }

          return selectedCriminal.map(function (item) {
            return item.get('display');
          });
        }

        return false;
      }
    }),
    subjectAnalyticsCriminalCharges: Ember.computed('criminalCharge', {
      get: function get() {
        var criminalCharge = this.criminalCharge;

        if (criminalCharge) {
          return criminalCharge.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var noneOption = value.criminalAnalysis.findBy('shortName', 'no_criminal');

        if (noneOption.get('checked')) {
          this.set('criminalCharge', null);
          return null;
        }

        var selectedCriminalCharges = value.criminalCharges.filterBy('checked', true);

        if (selectedCriminalCharges) {
          this.set('criminalCharge', selectedCriminalCharges.map(function (c) {
            return c.get('display');
          }).join(', '));
          return selectedCriminalCharges.map(function (item) {
            return item.get('display');
          });
        }

        return false;
      }
    }),
    subjectAnalyticsCriminalRights: Ember.computed('criminalRights', {
      get: function get() {
        var criminalRights = this.criminalRights;

        if (criminalRights) {
          return criminalRights.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var noneOption = value.criminalAnalysis.findBy('shortName', 'no_criminal');
        var hasFelonyConvictions = value.criminalCharges.findBy('shortName', 'has_felony_convictions');

        if (noneOption.get('checked') || !hasFelonyConvictions.get('checked')) {
          this.set('criminalRights', null);
          return null;
        }

        var selectedCriminalRights = this.criminalRights;

        if (selectedCriminalRights) {
          return [selectedCriminalRights];
        }

        return false;
      }
    }),
    subjectAnalyticsCriminalRightsRead: Ember.computed('subjectCriminalRights', function () {
      return "\n            ".concat(this.subjectCriminalRights.map(function (item) {
        return "\n              ".concat(item, "<br/>\n            ").trim();
      }).join(''), "\n          ");
    }),
    subjectAnalyticsTraffic: Ember.computed('trafficAnalysis', {
      get: function get() {
        var traffic = this.trafficAnalysis;

        if (traffic) {
          return traffic.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedTraffic = value.filterBy('checked', true);

        if (selectedTraffic) {
          this.set('trafficAnalysis', selectedTraffic.map(function (c) {
            return c.get('display');
          }).join(', '));
          return selectedTraffic.map(function (item) {
            return item.get('display');
          });
        }

        return null;
      }
    }),
    subjectAnalyticsEmploymentStatus: Ember.computed('employmentStatus', {
      get: function get() {
        var employmentStatus = this.employmentStatus;

        if (employmentStatus) {
          return employmentStatus.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedEmploymentStatus = value.employmentStatus.filterBy('checked', true).rejectBy('display', 'Unknown');
        var unknownOption = value.employmentStatus.findBy('shortName', 'employment_status_unknown');

        if (unknownOption.get('checked')) {
          this.set('employmentStatus', unknownOption.get('display'));
          return [unknownOption.get('display')];
        }

        if (selectedEmploymentStatus) {
          this.set('employmentStatus', selectedEmploymentStatus.map(function (e) {
            return e.get('display');
          }).join(', '));
          return selectedEmploymentStatus.map(function (item) {
            return item.get('display');
          });
        }

        return false;
      }
    }),
    subjectAnalyticsEmploymentAnalysisCategory: Ember.computed('employmentAnalysisCategory', {
      get: function get() {
        var employmentAnalysisCategory = this.employmentAnalysisCategory;

        if (employmentAnalysisCategory) {
          return employmentAnalysisCategory.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var employmentAnalysisCategory = value.employmentAnalysisCategory;
        var selectedEmploymentAnalysisCategory = employmentAnalysisCategory.filterBy('checked', true);

        if (selectedEmploymentAnalysisCategory) {
          this.set('employmentAnalysisCategory', selectedEmploymentAnalysisCategory.map(function (e) {
            return e.get('display');
          }).join(', '));
          return selectedEmploymentAnalysisCategory.map(function (item) {
            return item.get('display');
          });
        }

        return false;
      }
    }),
    subjectAnalyticsSocnetOther: Ember.computed('socnetOther', {
      get: function get() {
        var socnetOther = this.socnetOther;

        if (socnetOther) {
          return socnetOther.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        if (value) {
          var noneOption = value.socnetOtherOptions.findBy('shortName', 'none_above_apply');

          if (noneOption.get('checked')) {
            this.set('socnetOther', noneOption.get('display'));
            value.socnetOtherOptions.rejectBy('shortName', 'none_above_apply').forEach(function (st) {
              st.set('checked', null);
            });
            return [noneOption.get('display')];
          }

          var selectedSocnetOtherOptions = value.socnetOtherOptions.filterBy('checked', true);

          if (selectedSocnetOtherOptions) {
            this.set('socnetOther', selectedSocnetOtherOptions.map(function (e) {
              return e.get('display');
            }).join(', '));
            return selectedSocnetOtherOptions.map(function (item) {
              return item.get('display');
            });
          }

          return false;
        }

        return false;
      }
    }),
    subjectAnalyticsSocnetThemes: Ember.computed('socnetThemes', {
      get: function get() {
        var socnetThemes = this.socnetThemes;

        if (socnetThemes) {
          return socnetThemes.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        if (value) {
          var noneOption = value.socnetThemesOptions.findBy('shortName', 'no_socnet_themes');

          if (noneOption.get('checked')) {
            this.set('socnetThemes', noneOption.get('display'));
            value.socnetThemesOptions.forEach(function (st) {
              st.set('checked', null);
            });
            value.socnetThemesHiddenOptions.forEach(function (st) {
              st.set('checked', null);
            });
            return [noneOption.get('display')];
          }

          var selectedSocnetThemesOptions = value.socnetThemesOptions.filterBy('checked', true);

          if (selectedSocnetThemesOptions) {
            this.set('socnetThemes', selectedSocnetThemesOptions.map(function (e) {
              return e.get('display');
            }).join(', '));
            return selectedSocnetThemesOptions.map(function (item) {
              return item.get('display');
            });
          }

          return false;
        }

        return false;
      }
    }),
    subjectAnalyticsSocnetThemesHidden: Ember.computed('socnetThemesHidden', {
      get: function get() {
        var socnetThemesHidden = this.socnetThemesHidden;

        if (socnetThemesHidden) {
          return socnetThemesHidden.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        if (value) {
          var noneOption = value.socnetThemesOptions.findBy('shortName', 'no_socnet_themes');

          if (noneOption.get('checked')) {
            this.set('socnetThemesHidden', '');
            value.socnetThemesHiddenOptions.forEach(function (st) {
              st.set('checked', null);
            });
            return [];
          }

          var selectedSocnetThemesOptions = value.socnetThemesHiddenOptions.filterBy('checked', true);

          if (selectedSocnetThemesOptions) {
            this.set('socnetThemesHidden', selectedSocnetThemesOptions.map(function (e) {
              return e.get('display');
            }).join(', '));
            return selectedSocnetThemesOptions.map(function (item) {
              return item.get('display');
            });
          }

          return false;
        }

        return false;
      }
    }),
    subjectAnalyticsPossibleConnectionsCategories: Ember.computed('possibleConnectionsCategory', {
      get: function get() {
        var possibleConnectionsCategory = this.possibleConnectionsCategory;

        if (possibleConnectionsCategory) {
          return possibleConnectionsCategory.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var noneOption = value.possibleConnectionsCategoryOptions.findBy('shortName', 'no_possible_connections');

        if (noneOption.get('checked')) {
          this.set('possibleConnectionsCategory', noneOption.get('display'));
          value.possibleConnectionsCategoryOptions.rejectBy('shortName', 'no_possible_connections').forEach(function (st) {
            st.set('checked', null);
          });
          return [noneOption.get('display')];
        }

        var selectedPossibleConnectionsCategoryOptions = value.possibleConnectionsCategoryOptions.filterBy('checked', true);

        if (selectedPossibleConnectionsCategoryOptions) {
          this.set('possibleConnectionsCategory', selectedPossibleConnectionsCategoryOptions.map(function (p) {
            return p.get('display');
          }).join(', '));
          return selectedPossibleConnectionsCategoryOptions.map(function (item) {
            return item.get('display');
          });
        }

        return false;
      }
    }),
    subjectAnalyticsYearsMarried: Ember.computed('maritalStatus', {
      // TODO: What is going on with these getters/setters? (here down)

      /* eslint-disable ember/require-return-from-computed */
      get: function get() {},
      set: function set() {
        var maritalStatus = this.maritalStatus;

        if (maritalStatus !== 'Married') {
          this.set('yearsMarried', null);
        }
      }
    }),
    subjectAnalyticsHasChildren: Ember.computed('children', 'hasChildren', {
      /* eslint-disable ember/require-return-from-computed */
      get: function get() {},
      set: function set() {
        var children = this.children;

        if (children !== 'Has children') {
          this.set('hasChildren', null);
        }
      }
    }),
    subjectAnalyticsEmploymentType: Ember.computed('employmentType', {
      get: function get() {
        var employmentType = this.employmentType;

        if (employmentType) {
          return employmentType.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedEmploymentOptions = value.employmentDetailsOptions.filterBy('checked', true);

        if (selectedEmploymentOptions) {
          this.set('employmentType', selectedEmploymentOptions.map(function (e) {
            return e.get('display');
          }).join(', '));
          return selectedEmploymentOptions.map(function (item) {
            return item.get('display');
          });
        }

        return false;
      }
    }),
    subjectAnalyticsEmploymentRole: Ember.computed('employmentRole', {
      get: function get() {
        var employmentRole = this.employmentRole;

        if (employmentRole) {
          return employmentRole.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedEmploymentOptions = value.employmentDetailsOptions.filterBy('checked', true);

        if (selectedEmploymentOptions) {
          this.set('employmentRole', selectedEmploymentOptions.map(function (e) {
            return e.get('role');
          }).join(', '));
          return selectedEmploymentOptions.map(function (item) {
            return item.get('role');
          });
        }

        return false;
      }
    }),
    subjectAnalyticsEmploymentOther: Ember.computed('employmentOther', {
      get: function get() {
        var employmentOther = this.employmentOther;

        if (employmentOther) {
          return employmentOther.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedEmploymentOptions = value.employmentDetailsOptions.filterBy('checked', true);

        if (selectedEmploymentOptions) {
          this.set('employmentOther', selectedEmploymentOptions.map(function (e) {
            return e.get('other');
          }).join(', '));
          return selectedEmploymentOptions.map(function (item) {
            return item.get('other');
          });
        }

        return false;
      }
    }),
    subjectAnalyticsProductUse: Ember.computed('productUse', {
      get: function get() {
        var productUse = this.productUse;

        if (productUse) {
          return productUse.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedProductUseOptions = value.productUseOptions.filterBy('checked', true);

        if (selectedProductUseOptions) {
          this.set('productUse', selectedProductUseOptions.map(function (p) {
            return p.get('display');
          }).join(', '));
          return selectedProductUseOptions.map(function (item) {
            return item.get('display');
          });
        }

        return false;
      }
    }),
    subjectAnalyticsProductUseSecondaries: Ember.computed('productUseSecondaries', {
      get: function get() {
        var productUseSecondaries = this.productUseSecondaries;

        if (productUseSecondaries) {
          return productUseSecondaries.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      }
    }),
    subjectAnalyticsJurorMedicalIssues: Ember.computed('jurorMedicalIssues', {
      get: function get() {
        var jurorMedicalIssues = this.jurorMedicalIssues;

        if (jurorMedicalIssues) {
          return jurorMedicalIssues.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedMedicalOptions = value.medicalOptions.filterBy('checked', true);

        if (selectedMedicalOptions) {
          this.set('jurorMedicalIssues', selectedMedicalOptions.map(function (m) {
            return m.get('display');
          }).join(', '));
          return selectedMedicalOptions.map(function (item) {
            return item.get('display');
          });
        }

        return false;
      }
    }),
    subjectAnalyticsMediaOutlet: Ember.computed('mediaOutlet', {
      get: function get() {
        var mediaOutlet = this.mediaOutlet;

        if (mediaOutlet) {
          return mediaOutlet.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedMediaOutletOptions = value.mediaOutletOptions.filterBy('checked', true);

        if (selectedMediaOutletOptions) {
          this.set('mediaOutlet', selectedMediaOutletOptions.map(function (h) {
            return h.get('display');
          }).join(', '));
          return selectedMediaOutletOptions.map(function (item) {
            return item.get('display');
          });
        }

        return false;
      }
    }),
    subjectAnalyticsHigherEducation: Ember.computed('higherEducation', {
      get: function get() {
        var higherEducation = this.higherEducation;

        if (higherEducation) {
          return higherEducation.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedHigherEducationOptions = value.higherEducationOptions.filterBy('checked', true);

        if (selectedHigherEducationOptions) {
          this.set('higherEducation', selectedHigherEducationOptions.map(function (h) {
            return h.get('display');
          }).join(', '));
          var heArr = selectedHigherEducationOptions.map(function (item) {
            return item.get('display');
          });

          if (!heArr.includes('Masters')) {
            this.set('higherEducationMasters', null);
          }

          if (!heArr.includes('Post - Grad')) {
            this.set('higherEducationPostGrad', null);
          }

          return heArr;
        }

        return false;
      }
    }),
    subjectAnalyticsReligiousAffiliation: Ember.computed('religiousAffiliation', {
      get: function get() {
        var religiousAffiliation = this.religiousAffiliation;

        if (religiousAffiliation) {
          return religiousAffiliation.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedReligiousAffiliationOptions = value.religiousAffiliationOptions.filterBy('checked', true);

        if (selectedReligiousAffiliationOptions) {
          this.set('religiousAffiliation', selectedReligiousAffiliationOptions.map(function (h) {
            return h.get('display');
          }).join(', '));
          return selectedReligiousAffiliationOptions.map(function (item) {
            return item.get('display');
          });
        }

        return false;
      }
    }),
    subjectAnalyticsReligiousLevelOfInterest: Ember.computed('religiousLevelOfInterest', {
      get: function get() {
        var religiousLevelOfInterest = this.religiousLevelOfInterest;

        if (religiousLevelOfInterest) {
          return religiousLevelOfInterest.split(', ').map(function (item) {
            return item.trim();
          });
        }

        return null;
      },
      set: function set(key, value) {
        var selectedReligiousLevelOfInterestOptions = value.religiousLevelOfInterestOptions.filterBy('checked', true);

        if (selectedReligiousLevelOfInterestOptions) {
          this.set('religiousLevelOfInterest', selectedReligiousLevelOfInterestOptions.map(function (h) {
            return h.get('display');
          }).join(', '));
          return selectedReligiousLevelOfInterestOptions.map(function (item) {
            return item.get('display');
          });
        }

        return false;
      }
    }),
    // workflow related fields
    adminMainA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    adminMainC: _emberData.default.attr('string'),
    adminMainS: _emberData.default.attr('string'),
    adminMainHours: _emberData.default.attr('number'),
    adminMainDeadline: _emberData.default.attr('string'),
    adminRecordsA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    adminRecordsC: _emberData.default.attr('string'),
    adminRecordsS: _emberData.default.attr('string'),
    adminRecordsHours: _emberData.default.attr('number'),
    adminRecordsDeadline: _emberData.default.attr('string'),
    adminReportA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    adminReportC: _emberData.default.attr('string'),
    adminReportS: _emberData.default.attr('string'),
    adminReportHours: _emberData.default.attr('number'),
    adminReportDeadline: _emberData.default.attr('string'),
    analyticsPhase1A: _emberData.default.belongsTo('employee', {
      async: false
    }),
    analyticsPhase1C: _emberData.default.attr('string'),
    analyticsPhase1S: _emberData.default.attr('string'),
    analyticsPhase1Hours: _emberData.default.attr('number'),
    analyticsPhase1Deadline: _emberData.default.attr('string'),
    analyticsPhase2A: _emberData.default.belongsTo('employee', {
      async: false
    }),
    analyticsPhase2C: _emberData.default.attr('string'),
    analyticsPhase2S: _emberData.default.attr('string'),
    analyticsPhase2Hours: _emberData.default.attr('number'),
    analyticsPhase2Deadline: _emberData.default.attr('string'),
    caseMgrA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    caseMgrC: _emberData.default.attr('string'),
    caseMgrS: _emberData.default.attr('string'),
    caseMgrHours: _emberData.default.attr('number'),
    caseMgrDeadline: _emberData.default.attr('string'),
    dbInitialA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    dbInitialC: _emberData.default.attr('string'),
    dbInitialS: _emberData.default.attr('string'),
    dbInitialHours: _emberData.default.attr('number'),
    dbInitialDeadline: _emberData.default.attr('string'),
    dbMainA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    dbMainC: _emberData.default.attr('string'),
    dbMainS: _emberData.default.attr('string'),
    dbMainHours: _emberData.default.attr('number'),
    dbMainDeadline: _emberData.default.attr('string'),
    dbMonitorA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    dbMonitorC: _emberData.default.attr('string'),
    dbMonitorS: _emberData.default.attr('string'),
    dbMonitorHours: _emberData.default.attr('number'),
    dbMonitorDeadline: _emberData.default.attr('string'),
    dbSuppA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    dbSuppC: _emberData.default.attr('string'),
    dbSuppS: _emberData.default.attr('string'),
    dbSuppHours: _emberData.default.attr('number'),
    dbSuppDeadline: _emberData.default.attr('string'),
    dbWitsA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    dbWitsC: _emberData.default.attr('string'),
    dbWitsS: _emberData.default.attr('string'),
    dbWitsHours: _emberData.default.attr('number'),
    dbWitsDeadline: _emberData.default.attr('string'),
    fieldMainA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    fieldMainC: _emberData.default.attr('string'),
    fieldMainS: _emberData.default.attr('string'),
    fieldMainHours: _emberData.default.attr('number'),
    fieldMainDeadline: _emberData.default.attr('string'),
    fieldSuppA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    fieldSuppC: _emberData.default.attr('string'),
    fieldSuppS: _emberData.default.attr('string'),
    fieldSuppHours: _emberData.default.attr('number'),
    fieldSuppDeadline: _emberData.default.attr('string'),
    officeBkA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeBkC: _emberData.default.attr('string'),
    officeBkS: _emberData.default.attr('string'),
    officeBkHours: _emberData.default.attr('number'),
    officeBkDeadline: _emberData.default.attr('string'),
    officeCourtsA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeCourtsC: _emberData.default.attr('string'),
    officeCourtsS: _emberData.default.attr('string'),
    officeCourtsHousr: _emberData.default.attr('number'),
    officeCourtsDeadline: _emberData.default.attr('string'),
    officeDlA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeDlC: _emberData.default.attr('string'),
    officeDlS: _emberData.default.attr('string'),
    officeDlHours: _emberData.default.attr('number'),
    officeDlDeadline: _emberData.default.attr('string'),
    officeLeA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeLeC: _emberData.default.attr('string'),
    officeLeS: _emberData.default.attr('string'),
    officeLeHours: _emberData.default.attr('number'),
    officeLeDeadline: _emberData.default.attr('string'),
    officeMainA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeMainC: _emberData.default.attr('string'),
    officeMainS: _emberData.default.attr('string'),
    officeMainHours: _emberData.default.attr('number'),
    officeMainDeadline: _emberData.default.attr('string'),
    officeSocnetA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeSocnetC: _emberData.default.attr('string'),
    officeSocnetS: _emberData.default.attr('string'),
    officeSocnetHours: _emberData.default.attr('number'),
    officeSocnetDeadline: _emberData.default.attr('string'),
    officeWA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeWC: _emberData.default.attr('string'),
    officeWS: _emberData.default.attr('string'),
    officeWHours: _emberData.default.attr('number'),
    officeWDeadline: _emberData.default.attr('string'),
    prePublishA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    prePublishC: _emberData.default.attr('string'),
    prePublishS: _emberData.default.attr('string'),
    prePublishHours: _emberData.default.attr('number'),
    prePublishDeadline: _emberData.default.attr('string'),
    qaInitialA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    qaInitialC: _emberData.default.attr('string'),
    qaInitialS: _emberData.default.attr('string'),
    qaInitialHours: _emberData.default.attr('number'),
    qaInitialDeadline: _emberData.default.attr('string'),
    qaMainA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    qaMainC: _emberData.default.attr('string'),
    qaMainS: _emberData.default.attr('string'),
    qaMainHours: _emberData.default.attr('number'),
    qaMainDeadline: _emberData.default.attr('string'),
    qaSuppA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    qaSuppC: _emberData.default.attr('string'),
    qaSuppS: _emberData.default.attr('string'),
    qaSuppHours: _emberData.default.attr('number'),
    qaSuppDeadline: _emberData.default.attr('string'),
    rptMainA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    rptMainC: _emberData.default.attr('string'),
    rptMainS: _emberData.default.attr('string'),
    rptMainHours: _emberData.default.attr('number'),
    rptMainDeadline: _emberData.default.attr('string'),
    rptSuppA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    rptSuppC: _emberData.default.attr('string'),
    rptSuppS: _emberData.default.attr('string'),
    rptSuppHours: _emberData.default.attr('number'),
    rptSuppDeadline: _emberData.default.attr('string'),
    serializeWithConflictResolution: function serializeWithConflictResolution(options) {
      var snapshot = this._internalModel.createSnapshot();

      var serializer = this.store.serializerFor('subject');
      var data = {};
      var type = snapshot.type;
      serializer.serializeIntoHash(data, type, snapshot, options);
      return data.subject;
    },
    updateClientData: (0, _emberApiActions.memberAction)({
      path: 'update-client-data',
      type: 'put',
      before: function before() {
        var payload = this.serialize(); // eslint-disable-next-line no-unused-vars, no-restricted-syntax

        for (var _i = 0, _Object$entries = Object.entries(payload); _i < _Object$entries.length; _i++) {
          var _Object$entries$_i = (0, _slicedToArray2.default)(_Object$entries[_i], 2),
              fieldName = _Object$entries$_i[0],
              value = _Object$entries$_i[1];

          if (fieldName === 'location') {
            continue;
          }

          if (fieldName === 'seat_number') {
            continue;
          }

          if (!fieldName.startsWith('client_')) {
            delete payload[fieldName];
          }
        }

        return payload;
      }
    })
  });

  _exports.default = _default;
});